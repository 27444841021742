<template>
  <div>
    <h6 class="mb-6">Complete Profile</h6>

    <p class="font-thin mb-6">
      Tell us about yourself
    </p>

    <form
      v-if="form"
      @submit.prevent="submit"
    >
      <div class="max-h-400px mb-5 overflow-y-auto">
        <div class="gap-4 grid grid-cols-2 mb-10">
          <template v-for="(field, fieldIndex) in form.data">
            <div
              :class="`col-span-${field.cols || 2}`"
              :key="fieldIndex"
            >
              <label
                v-if="field.type === 'date_of_birth'"
                class="block mb-2 pl-5 text-sm text-gray-600"
              >
                {{ field.label }}
              </label>
              <form-group
                v-model="field.value"
                :name="field.name"
                :type="field.type"
                :options="field.options"
                :form="form"
                class="mb-0"
                :class="field.class"
              >
                {{ getPropValue(field.label) }}
              </form-group>
              <div v-if="field.name === 'nin'" class="flex mt-3 mb-6">
                <div class="flex">
                  <ion-icon name="shield" class="text-green-500 mr-4 text-2xl"></ion-icon>
                  <div class="text-2xs mr-2 text-gray-500">
                    We’re mandated by CBN to keep your NIN and other vital information highly secured and confidential.
                  </div>
                </div>
                <div
                  class="text-2xs font-medium whitespace-no-wrap cursor-pointer text-right mt-2"
                  @click.prevent="$emit('show-nin-help')"
                >
                  Forgot NIN?
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>

      <template v-if="getFormError(form)">
        <div class="alert alert-red-soft mb-6">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(form) }}</span>
        </div>
      </template>

      <button
        type="submit"
        class="btn btn-blue btn-md mt-auto relative w-full"
        :disabled="form.loading"
      >
        <sm-loader class="sm-loader-white" v-if="form.loading" />
        <span v-else>Next</span>
        <i class="absolute inline-flex right-20px top-1/2" style="transform: translateY(-50%);">
          <ion-icon name="arrow-forward-outline" />
        </i>
      </button>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Form2Profile',
  data() {
    return {
      form: this.$options.basicForm([]),
    };
  },
  computed: {
    ...mapState(['industries']),
    fields() {
      return [
        // {
        //   prop: 'profile.date_of_birth',
        //   name: 'date_of_birth',
        //   label: 'Date of birth *',
        //   type: 'date_of_birth',
        // },
        {
          prop: 'profile.marital_status',
          name: 'marital_status',
          label: 'Marital Status *',
          type: 'marital-status',
        },
        {
          prop: 'profile.gender',
          name: 'gender',
          label: 'Gender *',
          type: 'gender',
        },
        {
          prop: 'profile.address',
          name: 'address',
          label: 'Home Address *',
          type: 'textarea',
        },
        {
          prop: 'profile.employment_status',
          name: 'employment_status',
          label: 'Employment Status *',
          type: 'employment-status',
        },
        {
          prop: 'profile.employer',
          name: 'employer',
          label: this.isSelfEmployed ? 'Business Name *' : 'Company name *',
        },
        {
          prop: 'profile.position',
          name: 'position',
          label: 'Job Title *',
          show: () => this.isEmployed,
        },
        {
          prop: 'profile.salary',
          name: 'salary',
          label: 'Monthly salary / income *',
          type: 'money',
        },
        {
          prop: 'profile.emp_type',
          name: 'emp_type',
          label: 'Employment Type *',
          type: 'employment-type',
          show: () => this.isEmployed,
        },
        {
          prop: 'profile.company_industry',
          name: 'company_industry',
          label: 'Business category *',
          type: 'select',
          options: this.industries,
          show: () => this.isSelfEmployed,
        },
        {
          prop: 'profile.company_description',
          name: 'company_description',
          label: 'Business Description *',
          type: 'textarea',
          show: () => this.isSelfEmployed,
        },
        {
          prop: 'profile.company_start_year',
          name: 'company_start_year',
          label: 'Year *',
          type: 'select',
          options: this.yearOptions,
          cols: 1,
          show: () => this.isSelfEmployed,
        },
        {
          prop: 'profile.company_start_month',
          name: 'company_start_month',
          label: 'Month *',
          type: 'select',
          options: this.monthOptions,
          cols: 1,
          show: () => this.isSelfEmployed,
        },
        {
          prop: 'profile.company_registration_number',
          name: 'company_registration_number',
          label: 'Company registration number',
          rules: 'nullable',
          show: () => this.isSelfEmployed,
        },
        {
          prop: 'profile.previous_formal_job',
          name: 'previous_formal_job',
          label: 'Previous formal job',
          rules: 'nullable',
          show: () => this.isSelfEmployed,
        },
        {
          prop: 'profile.company_website',
          name: 'company_website',
          label: 'Business website',
          rules: 'nullable|url',
          show: () => this.isSelfEmployed,
        },
        {
          prop: 'profile.company_twitter_url',
          name: 'company_twitter_url',
          label: 'Business twitter page',
          rules: 'nullable|url',
          show: () => this.isSelfEmployed,
        },
        {
          prop: 'profile.company_instagram_url',
          name: 'company_instagram_url',
          label: 'Business instagram page',
          rules: 'nullable|url',
          show: () => this.isSelfEmployed,
        },
        {
          prop: 'profile.company_address',
          name: 'company_address',
          label: 'Company address',
          rules: 'nullable',
        },
        {
          prop: 'profile.company_monthly_income',
          name: 'company_monthly_income',
          label: 'Business monthly income',
          type: 'money',
          show: () => this.isSelfEmployed,
        },
        {
          prop: 'profile.company_monthly_profit',
          name: 'company_monthly_profit',
          label: 'Business monthly profit',
          type: 'money',
          show: () => this.isSelfEmployed,
        },
        {
          prop: 'profile.company_size',
          name: 'company_size',
          label: 'Total employees',
          type: 'number',
          rules: 'nullable',
          show: () => this.isSelfEmployed,
        },
        {
          prop: 'profile.salary_day',
          name: 'salary_day',
          label: this.isSelfEmployed ? 'Preferred billing day *' : 'Salary day *',
          type: 'salary-day',
        },
        {
          prop: 'profile.account_no|profile.trial_account_no',
          name: 'account_no',
          label: 'Bank account number *',
          rules: 'required|number|length:10',
        },
        {
          prop: 'profile.bank_name|profile.trial_bank_code',
          name: 'bank_name',
          label: 'Bank name *',
          type: 'bank',
        },
        // {
        //   prop: 'profile.nin|nin_verified_at',
        //   name: 'nin',
        //   label: 'NIN *',
        //   rules: 'required|number|length:11',
        // },
      ];
    },
    isEmployed() {
      return this.user.profile?.employment_status === 'employed';
    },
    isSelfEmployed() {
      return this.user.profile?.employment_status === 'self_employed';
    },
    monthOptions() {
      let months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      const now = new Date();
      const currentMonth = now.getMonth();
      const currentYear = now.getFullYear();
      const selectedYear = this.form.data.company_start_year?.value;
      // Is This Year
      if (selectedYear === currentYear) {
        // Display Till Current Month
        months = months.slice(0, currentMonth + 1);
      }
      return months;
    },
    yearOptions() {
      const start = 1960;
      const end = (new Date()).getFullYear();
      const years = [];
      for (let i = end; i > start - 1; i -= 1) {
        years.push({
          searchString: i,
          title: i,
          value: i,
        });
      }
      return years;
    },
  },
  mounted() {
    this.$emit('mounted');
    this.determineFields();
  },
  methods: {
    ...mapActions('session', ['updateUser']),
    determineFields() {
      const user = this.user;
      const fields = this.fields.filter((field) => {
        const isVisible = field.show ? field.show.apply(this) : true;
        let isFilled = field.prop.split('|')
          .some((props) => {
            return props.split('&').every((prop) => {
              return prop.split('.').reduce((parent, child) => parent[child], user);
            });
          });
        return !isFilled && isVisible;
      });
      this.form = this.$options.basicForm(fields);
    },
    getPropValue(prop) {
      if (prop?.constructor === Function) {
        return prop.apply(this);
      }
      return prop;
    },
    async submit() {
      if (!this.validateForm(this.form)) return false;

      this.form.error = null;
      this.form.setLoading();

      await this.sendRequest('personal.onboarding.profile', {
        data: this.getFormData(),
        success: (response) => {
          const { data: { user, profile, otp_verification_id } } = response.data;
          this.updateUser({ ...user, profile })
            .then(() => {
              const nin = this.getFormValue('nin');
              const data = { otp_verification_id, nin };
              this.$emit('update-profile', data);
            });
        },
        error: (error) => {
          this.form.error = error;
          this.mapFormErrors(this.form, error?.response?.data?.errors);
        },
      });

      this.form.setLoading(false);
    },
  }
};
</script>
